const React = require('react');
const { bool, shape, string } = require('prop-types');
const { loadable } = require('nordic/lazy');
const { RecommendationsCarouselSnapped } = require('@recommendations-fe/carousel-snapped');
const RecommendationsIconFactory = require('./icon-factory');
const ContextTemplate = require('./polycard-context');
const { RecommendationsCardHorizontalTitleFirst } = require('./recomendations-card');

const Polycard = loadable(() => import('../polycard/polycard'));

Polycard.preload();

const measuringHeightLoader = loadable(() => import('./polycard-utils'), {
  resolveComponent: components => components.measuringHeight,
});

const measuringHeight = measuringHeightLoader.preload();

const measuringFlagLoader = loadable(() => import('./polycard-utils'), {
  resolveComponent: components => components.measuringFlag,
});

const measuringFlag = measuringFlagLoader.preload();

const Recommendations = ({ isPolycard, thousandSeparator, decimalSeparator, ...props }) => {
  const measuringFunctions = isPolycard ? { measuringHeight, measuringFlag } : {};
  const apiRecommendations = isPolycard
    ? props?.apiresponse?.recommendation_info?.polycards
    : props?.apiresponse?.recommendation_info?.recommendations;
  const items = apiRecommendations?.length || 0;
  const countSlides = items < 3 && items !== 0 ? items : 3;

  const CardTemplate = cardProps =>
    isPolycard ? (
      <Polycard polycard={{ ...cardProps }} className="recos-polycard poly-card--mobile" />
    ) : (
      <RecommendationsCardHorizontalTitleFirst {...cardProps} />
    );

  return (
    <div className={items === 1 ? 'ui-pdp-container--top ui-pdp-container--card-one-item' : null}>
      <RecommendationsCarouselSnapped
        Card={CardTemplate}
        IconFactory={RecommendationsIconFactory}
        fixedHeight
        criticalImages={countSlides}
        slidesPerView={countSlides}
        Context={ContextTemplate}
        contextProps={{
          type: 'list-card',
          thousandSeparator,
          decimalSeparator,
        }}
        isPolycard={isPolycard}
        {...measuringFunctions}
        {...props}
      />
    </div>
  );
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  apiresponse: shape({}),
};

module.exports = Recommendations;
